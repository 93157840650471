/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from "react";
import { Editor, EditorState, RichUtils } from "draft-js";
import { isEmpty } from "lodash";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { WrappedFieldProps } from "redux-form";
import { cnField } from "components/Form";
import { cnMessages } from "components/Messages";
import { DRAFT_UTILS, STYLE_DRAFT_UTILS, VARIANT_DRAFT_UTILS } from "./TextareaType";

type Props = {
  className?: string;
  isEditor?: boolean;
  label: string;
  disabled: boolean;
  fieldId: string;
} & WrappedFieldProps;

const FormTextarea: React.FC<Props> = ({
  fieldId,
  disabled = false,
  className = "",
  isEditor = false,
  label,
  input,
  meta: { touched, error },
  ...rest
}) => {
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
  const [flagDraftFill, setFlagDraftFill] = useState<boolean>(false);
  const [stateFieldID, setStateFieldID] = useState<string>(fieldId);
  const editorRef = useRef<Editor>(null);
  const isError = touched && Boolean(error);

  useEffect(() => {
    if (!isEditor) return;
    if (input.value && !flagDraftFill) {
      if (isEmpty(fieldId)) {
        input.onChange(stateToHTML(editorState.getCurrentContent()));
      }
      const contentState = stateFromHTML(input.value);

      setEditorState(EditorState.createWithContent(contentState));
      if (input.value === "<p><br></p>") {
        setEditorState(editorState => EditorState.moveFocusToEnd(editorState));
      }
      setFlagDraftFill(true);
    }
  }, [input.value]);

  useEffect(() => {
    if (fieldId !== stateFieldID) {
      setFlagDraftFill(false);
      setStateFieldID(fieldId);
      setEditorState(EditorState.createEmpty());
    }
  }, [fieldId]);

  const handleChange = (editorState: EditorState) => {
    input.onChange(stateToHTML(editorState.getCurrentContent()));
    setEditorState(editorState);
  };

  const handleRichUtils = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, style: STYLE_DRAFT_UTILS, type: VARIANT_DRAFT_UTILS) => {
    event.preventDefault();
    switch (type) {
      case VARIANT_DRAFT_UTILS.BLOCK:
        handleChange(RichUtils.toggleBlockType(editorState, style));
        break;
      case VARIANT_DRAFT_UTILS.INLINE:
        handleChange(RichUtils.toggleInlineStyle(editorState, style));
        break;
    }
  };

  const focusEditor = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event.target instanceof HTMLElement && event.target.closest(".DraftEditor-editorContainer")) {
      return;
    }
    if (editorRef.current) {
      setEditorState(editorState => EditorState.moveFocusToEnd(editorState));
    }
  };

  return (
    <div
      className={`${cnField()} ${cnField("Textarea", { hasError: isError, isEditor })} ${className}`}
      id={`input_textarea_${input.name}_container`}
      onClick={focusEditor}
    >
      {label && (
        <span className="Label" id={`input_textarea_${input.name}_label`}>
          {label}
        </span>
      )}
      {isEditor && (
        <div className={cnField("Textarea-RichButton")} id={`input_textarea_${input.name}_rich_buttons`}>
          {DRAFT_UTILS.map(({ style, label, type }) => (
            <button
              type="button"
              key={style}
              onMouseDown={event => handleRichUtils(event, style, type)}
              id={`input_textarea_${input.name}_rich_button_${style}`}
            >
              {label}
            </button>
          ))}
        </div>
      )}
      {isEditor ? (
        <Editor
          {...input}
          editorState={editorState}
          onChange={handleChange}
          readOnly={disabled}
          ref={editorRef}
          {...rest}
          webDriverTestID={`input_textarea_${input.name}`}
        />
      ) : (
        <textarea {...input} {...rest} id={`input_textarea_${input.name}`} disabled={disabled} />
      )}
      {isError && (
        <span id={`input_textarea_${input.name}_error`} className={`${cnMessages()} ${cnMessages("Error")}`}>
          {error}
        </span>
      )}
    </div>
  );
};
export default FormTextarea;
