import React, { FC, useMemo, useState } from "react";
import { Icon, Button } from "ss-ui";
import { connect } from "react-redux";
import { has, isEmpty, get } from "lodash";
import { useTranslation } from "react-i18next";

import { typeKey, FIELD_TYPE, apiCallFetchFile, isDesktopBrowser } from "core";
import { Payload as PayloadKeyAttached } from "store/routines/keys/attached";
import { keyAttached } from "store/actions";

import { KeysActionsFile } from "components/Generic";
import { cnSettings } from "../Settings";

type Props = {
  field: FIELD_TYPE;
  item: typeKey;
  keyAttached: (payload: PayloadKeyAttached) => void;
};

const RowFile: FC<Props> = ({ item, field, keyAttached }) => {
  const { t } = useTranslation();
  const fileName = useMemo(() => get(item, field), [item, field]);
  const fileExtension = useMemo(() => fileName?.split(".").pop()?.toLowerCase(), [fileName]);
  const [file, setFile] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchFile = async () => {
    setFile("");
    setLoading(true);

    try {
      apiCallFetchFile(item.id, field, fileName)
        .then(base64 => setFile(base64))
        .catch(error => console.error("Error fetching image", error))
        .finally(() => setLoading(false));
    } catch (error) {
      console.error("Error fetching image", error);
    }
  };

  if (!has(item, field) || isEmpty(fileName)) return null;

  const handleKeyAttached = () => {
    keyAttached({
      id: item.id,
      fileName,
      property: field,
    });
  };

  return (
    <div className={cnSettings("Container")}>
      <div className={cnSettings("Label")}>{t(`manage_key_field_${field}_label`)}</div>
      <div className={cnSettings("Attached")}>
        <div className={cnSettings("Attached-Value InfoBoxKeySettings-Value-Small")}>
          <Icon name="icon-file" width={16} height={16} fill="#000" />
          <span title={fileName}>{fileName}</span>
        </div>
        <div className={cnSettings("Attached-Actions")}>
          <div className="Action">
            {["jpg", "jpeg", "png", "pdf", "txt"].includes(fileExtension) && !(isDesktopBrowser() && fileExtension === "pdf") && (
              <KeysActionsFile value={file} fileName={fileName} onClick={fetchFile} isLoading={isLoading} />
            )}
          </div>
          <Button
            className="Action tooltip tooltip-left"
            data-tooltip={t("tooltip-download")}
            icon="icon-download"
            onClick={() => handleKeyAttached()}
            theme="primary-link"
          />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = { keyAttached };

export default connect(null, mapDispatchToProps)(RowFile);
