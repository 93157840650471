import React, { FC, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";

import { FormTextarea } from "components/Form";

import { Validation, FIELD_TYPE, getFieldValue } from "core";

import { FieldContext } from "./Types";

type Props = {};

const fieldType = FIELD_TYPE.KEY;

const FieldKey: FC<Props> = () => {
  const { t } = useTranslation();

  const context = useContext(FieldContext);

  useEffect(() => {
    if (context.formData[fieldType]) {
      context.handleChange(fieldType, context.formData[fieldType]);
    } else {
      getFieldValue(fieldType, context.key).then(value => value && context.handleChange(fieldType, value));
    }
  }, [context.key]);

  return (
    <Field
      component={FormTextarea}
      label={t(`manage_key_field_${fieldType}_label`)}
      name={fieldType}
      placeholder={t(`manage_key_field_${fieldType}_placeholder`)}
      validate={[Validation.maxLength3072]}
    />
  );
};

export default FieldKey;
