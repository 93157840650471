import React, { FC } from "react";
import { cnModal, Modal, LoadingContainer } from "components/Generic";

type Props = {
  fileData: string;
  handleClose: () => void;
  fileName?: string;
  isLoading?: boolean;
};

const FileWatcher: FC<Props> = ({ fileData, handleClose, fileName, isLoading = false }) => {
  const typeFile = fileData.slice(fileData.indexOf(":") + 1, fileData.indexOf("/"));

  return (
    <Modal handleClose={handleClose} className={cnModal("Watcher")}>
      <div className={cnModal("Watcher-Header")} id="modal_cropper_header">
        <p title={fileName}>{fileName}</p>
      </div>
      <div className={cnModal("Watcher-Body")} id="modal_cropper_body">
        <LoadingContainer isLoading={isLoading}>
          {typeFile === "image" && <img src={fileData} alt="Watcher" />}
          {["application", "text"].includes(typeFile) && <iframe src={fileData} title={fileName} />}
        </LoadingContainer>
      </div>
    </Modal>
  );
};

export default FileWatcher;
