import React, { FC, useState, ChangeEvent, useContext, useEffect, useMemo } from "react";
import { Icon } from "ss-ui";
import { getSizeImage, FIELD_TYPE, base64ToFile, apiCallFetchFile } from "core";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { get } from "lodash";

import { ImageCropper, ButtonDiv, FileWatcher } from "components/Generic";

import { cnManageKey } from "pages/ManageKey/ManageKey";

import { FieldContext } from "./Types";

type Props = {};

const fieldType = FIELD_TYPE.QR_BARCODE;

const FieldQRBarcode: FC<Props> = () => {
  const { t } = useTranslation();

  const context = useContext(FieldContext);
  const fileName = useMemo(() => get(context.key?.id, fieldType), [context.key?.id, fieldType]);

  const [base64, setBase64] = useState<string | undefined>(undefined);
  const [imageSrc, setImageSrc] = useState<any | null>(null);
  const [imageType, setImageType] = useState<string>("image/png");
  const [name, setName] = useState<string | undefined>(undefined);
  const [imageWidth, setImageWidth] = useState<number>(1);
  const [imageHeight, setImageHeight] = useState<number>(1);
  const [imageModal, setImageModal] = useState<boolean>(false);

  const [errors, setErrors] = useState({
    maxSize: false,
    type: false,
  });

  useEffect(() => {
    if (context.key?.[fieldType] && !context.formData[fieldType]) {
      setName(context.key?.[fieldType]);

      if (context.key?.id) {
        apiCallFetchFile(context.key.id, fieldType, fileName).then(base64 => {
          setBase64(base64);
        });
      }
    } else if (context.formData[fieldType] && context.formData[fieldType] !== "0") {
      const reader = new FileReader();
      context.handleChange(fieldType, context.formData[fieldType]);
      setName(context.formData[fieldType].name);
      reader.readAsDataURL(context.formData[fieldType]);
      reader.onload = () => {
        const imgUrl = reader.result;
        setBase64(String(imgUrl));
      };
    }
  }, [context.key]);

  const handleRemove = () => {
    context.handleChange(fieldType, "0");
    setName("");
    setImageSrc(null);
  };

  const handleUpload = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    const maxFileSize: number = 5;
    const reader = new FileReader();

    if (files && files[0]) {
      if (files[0].size / 1000000 > maxFileSize) {
        toast.error(t("validate_avatar_size_limit_mb", { size: maxFileSize }));
        setErrors(e => ({ ...e, maxSize: true }));
      } else if (!["image/jpeg", "image/png", "image/jpg"].includes(files[0].type)) {
        toast.error(t("validate_avatar_format"));
        setErrors(e => ({ ...e, type: true }));
      } else {
        setErrors(e => ({ ...e, maxSize: false }));
        setErrors(e => ({ ...e, type: false }));

        setImageType(files[0].type);
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          const imgUrl = reader.result;
          getImageWidthAndHeight(String(imgUrl));
          setImageSrc(imgUrl);
        };
      }
    }
  };

  const getImageWidthAndHeight = (imageUrl: string) => {
    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      setImageWidth(image.naturalWidth);
      setImageHeight(image.naturalHeight);
    };
  };

  const handleChange = async (value: string) => {
    if (getSizeImage(value) > 500) {
      toast.error(t("validate_avatar_size_limit_kb", { size: "500" }));
      setErrors(f => ({ ...f, maxSize: true }));
      context.handleChange(fieldType, "0");
      setImageSrc(null);
    } else {
      const file = await base64ToFile(value, "qr_barcode");
      context.handleChange(fieldType, file);
      setName(file.name);
      setBase64(value);
    }
  };

  return (
    <div className={cnManageKey("Logo")}>
      <div className="Field">
        <span className="label">{t(`manage_key_field_${fieldType}_label`)}</span>
      </div>
      {name && (
        <div onClick={() => setImageModal(true)} aria-hidden className={cnManageKey("Logo-Image")}>
          <div className={cnManageKey("Logo-Image-Mask")}>
            <Icon name="icon-eye" width={24} height={16} />
          </div>
          {base64 && <img src={base64} alt={fieldType} />}
          <div title={name} className={cnManageKey("Logo-Image-Name")}>
            {name}
          </div>
        </div>
      )}
      {name ? (
        <ButtonDiv id="btn_remove_icon" className={cnManageKey("Upload Remove-Logo")} onClick={handleRemove}>
          <Icon name="icon-trash" width={16} height={16} />
          <div className="text">{t("tooltip-delete")}</div>
        </ButtonDiv>
      ) : (
        <>
          <label className={cnManageKey("Upload")}>
            <Icon name="icon-upload" width={16} height={16} />
            <div className="text">{t(`manage_key_field_${fieldType}_placeholder`)}</div>
            <input onChange={handleUpload} type="file" name={fieldType} id="input_file_key_avatar" accept="image/*" />
          </label>
        </>
      )}

      {errors.maxSize && <div className="error">{t("validate_avatar_size_limit_kb", { size: 500 })}</div>}
      {errors.type && <div className="error">{t("validate_avatar_format")}</div>}

      {imageSrc && (
        <div className={cnManageKey("Crop")}>
          <ImageCropper
            aspect={imageWidth / imageHeight}
            cropArea={false}
            cropHeight={500}
            cropWidth={500}
            handleClose={() => setImageSrc(null)}
            image={imageSrc}
            onChangeImage={handleChange}
            setImageValue={setImageSrc}
            type={imageType}
          />
        </div>
      )}

      {imageModal && base64 && <FileWatcher fileData={base64} handleClose={() => setImageModal(!imageModal)} fileName={name} />}
    </div>
  );
};

export default FieldQRBarcode;
