import React, { FC, useState } from "react";
import { Button } from "ss-ui";
import { FileWatcher } from "components/Generic";
import { useTranslation } from "react-i18next";

type Props = {
  value: string;
  fileName?: string;
  onClick?: () => void;
  isLoading?: boolean;
};

const KeysActionsFile: FC<Props> = ({ value, fileName, onClick, isLoading }) => {
  const { t } = useTranslation();
  const [isOpenModalImage, setIsOpenModalImage] = useState<boolean>(false);

  const handleButtonClick = () => {
    setIsOpenModalImage(!isOpenModalImage);
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <Button
        className="OpenImage tooltip tooltip-left"
        data-tooltip={t("tooltip-pass-show")}
        icon="icon-eye"
        onClick={handleButtonClick}
        theme="primary-link"
      />
      {isOpenModalImage && (
        <FileWatcher fileName={fileName} fileData={value} handleClose={() => setIsOpenModalImage(false)} isLoading={isLoading} />
      )}
    </>
  );
};

export default KeysActionsFile;
