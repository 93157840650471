import { push } from "connected-react-router";
import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { put, takeLatest } from "redux-saga/effects";

import {
  clearUrlParam,
  getDataFromLS,
  getHash,
  LOCAL_STORAGE,
  ProtectedRoutes,
  removeDataFromLS,
  saveUserToLocalStorage,
} from "core";

export const action: Routine = createRoutine("LOGIN_YUBIKEY");

export type Payload = {
  access_token: string;
  expires_in: number;
  locale: string;
  master_password_checker?: string;
  otp_token_expired: string;
  password: string;
  refresh_token: string;
  roles: string;
  scope: string;
  token_type: string;
  username: string;
};

function* handler({ payload }: Action<Payload>) {
  try {
    saveUserToLocalStorage({ ...payload, isMicrosoft: false, isUnsubscribe: false });
    yield put(action.success({ ...payload, isMicrosoft: false, isUnsubscribe: false }));

    const prevURL = getDataFromLS(LOCAL_STORAGE.PREVIOUS_SESSION_URL);
    const copyURL = getDataFromLS(LOCAL_STORAGE.SEND_URL);

    yield removeDataFromLS(LOCAL_STORAGE.PREVIOUS_SESSION_URL);

    if (copyURL && copyURL.send_url.split("/").includes("login") !== true) {
      window.location = copyURL.send_url;
    } else if (prevURL && getHash(payload.username) === prevURL.username && prevURL.url.split("/").includes("login") !== true) {
      window.location = prevURL.url;
    } else {
      yield put(push(clearUrlParam(ProtectedRoutes.Keys)));
    }
    yield removeDataFromLS(LOCAL_STORAGE.SEND_URL);
  } catch (error) {
    yield put(action.failure(error));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
