import { isEmpty, get } from "lodash";

import { getPublicApiUrl } from "core/helpers/functions/url";
import { ICustomize } from "core/types";

import packageJson from "../../../../package.json";

/**
 * Get additional login data, for request
 * @param otp {string} - One time password
 */
export function additionalLoginData(otp?: string) {
  return {
    app_version: packageJson.version,
    grant_type: "password",
    device_id: navigator.appCodeName,
    device_name: navigator.platform,
    otp: otp || "otp",
  };
}

/**
 * Get navigator data
 */
export function navigatorData() {
  return {
    device_id: navigator.appCodeName,
    device_name: navigator.platform,
  };
}

/**
 * Return boolean if environment is equal development
 */
export function isEnvDevelop(): boolean {
  return process.env.NODE_ENV === "development";
}

/**
 * Give the user a file received from the server via an api request
 * @param blob - File data
 * @param fileName - how the file will be named when downloading
 */
export function sendFileForDownloading(blob: Blob, fileName: string): void {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
}

export function parseCustomizeResponse(response: { [key: string]: string }): ICustomize {
  return {
    logoImageName: !isEmpty(get(response, "style_company_logo", "")) ? get(response, "style_company_logo") : undefined,
    logo: !isEmpty(get(response, "style_company_logo", "")) ? `${getPublicApiUrl()}${get(response, "style_company_logo")}` : undefined,
    backgroundLoginImageName: !isEmpty(get(response, "style_login_bg_image", "")) ? get(response, "style_login_bg_image") : undefined,
    backgroundLogin: !isEmpty(get(response, "style_login_bg_image", ""))
      ? `${getPublicApiUrl()}${get(response, "style_login_bg_image")}`
      : undefined,
    companyName: get(response, "style_full_company_name", undefined),
    companyAddress: get(response, "style_login_company_address", undefined),
    colorMain: get(response, "style_main_color", undefined),
    colorSub: get(response, "style_sub_color", undefined),
    footerEn: get(response, "style_footer_text_en", undefined),
    footerDe: get(response, "style_footer_text_de", undefined),
    fonts: undefined,
  };
}

/**
 * Determines the type of credit card based on the provided card number.
 *
 * @param {string} cc - The credit card number.
 * @returns {CreditCardInfo} An object containing the type of credit card (e.g. "visa", "mastercard", etc.) and its format.
 */
export interface CreditCardInfo {
  type: string | undefined;
  format: string;
}

export function creditCardType(cc: string): CreditCardInfo {
  const cardRules = [
    { name: "amex", pattern: /^3[47]/, format: "#### ###### ##### ####" },
    { name: "diners", pattern: /^30[0-5]/, format: "#### ###### #### #####" }, // Diners Club Carte Blanche
    { name: "diners", pattern: /^36/, format: "#### ###### #### #####" }, // Diners Club International
    { name: "diners", pattern: /^54/, format: "#### #### #### ##### ##" }, // Diners Club USA & Canada
    {
      name: "discover",
      pattern: /^(6011|65|64[4-9]|622(1[2-9][0-9]|[2-8][0-9][0-9]|9[0-1][0-9]|92[0-5]))/,
      format: "#### #### #### #### ###",
    },
    { name: "jcb", pattern: /^35(2[89]|[3-8][0-9])/, format: "#### #### #### #### ###" },
    { name: "maestro", pattern: /^(5018|5020|5038|5893|6304|6759|676[1-3])/, format: "#### #### #### #### ###" },
    { name: "mastercard", pattern: /^(5[1-5]|2[2-7])/, format: "#### #### #### #### ###" },
    { name: "visa", pattern: /^(4026|4175|4508|4844|491[37])/, format: "#### #### #### #### ###" }, // Visa Electron
    { name: "visa", pattern: /^4/, format: "#### #### #### #### ###" },
    { name: "china_union_pay", pattern: /^(6[02]|81)/, format: "#### #### #### #### ###" },
  ];

  for (const rule of cardRules) {
    if (rule.pattern.test(cc)) {
      return { type: rule.name, format: rule.format };
    }
  }

  return { type: undefined, format: "#### #### #### #### ###" };
}

export const isValidEmail = (email: string): boolean => {
  const emailRegExp = new RegExp("^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,20}$", "i");
  return emailRegExp.test(email);
};

/**
 * Returns the MIME type of a given file name.
 *
 * @param {string} fileName - The name of the file.
 * @returns {string} The MIME type of the file.
 */
export function getMimeType(fileName: string): string {
  const extension = fileName?.split(".").pop()?.toLowerCase();

  switch (extension) {
    case "pdf":
      return "application/pdf";
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "zip":
      return "application/zip";
    case "txt":
      return "text/plain";
    default:
      return "application/octet-stream";
  }
}
