import { typeUser, saveDataToLS, LOCAL_STORAGE, SecurityServices } from "core";
import { get } from "lodash";

/**
 * Get user object from users list by user id
 * @param users - array of users
 * @param id - string ID
 */
export function getUserFromListByID(users: typeUser[], id: string | undefined): typeUser {
  return users.filter((user: typeUser) => user.id === id)[0];
}

/**
 * Get exist username from Users array list
 * @param users - users array list
 */
export function getFlatUsernameList(users: typeUser[]): string[] {
  return users.map((user: typeUser) => user.username);
}

export function saveUserToLocalStorage(payload) {
  saveDataToLS(LOCAL_STORAGE.USER, {
    expiresInTime: new Date().getTime() + (payload.expires_in || 3600) * 1000,
    isMicrosoft: get(payload, "isMicrosoft", undefined),
    isUnsubscribe: get(payload, "isUnsubscribe", undefined),
    refreshToken: get(payload, "refresh_token", undefined),
    refreshToken_expires: get(payload, "refresh_token_expires_at", undefined),
    role: payload.roles,
    saveAccount: !!payload.save_account,
    scope: payload.scope,
    securityKey: SecurityServices.createEncryptionKey(payload.password || payload.username),
    token: get(payload, "access_token", get(payload, "token", undefined)),
    token_expires: get(payload, "access_token_expires_at", undefined),
    tokenType: payload.token_type,
    unsubscribeToken: get(payload, "unsubscribeToken", undefined),
    username: get(payload, "username", undefined),
    userId: get(payload, "userId", undefined),
    publicKey: get(payload, "public_key", undefined),
    accessKey: get(payload, "user_access_key", undefined),
    checkPrivate: get(payload, "user_check_priv", undefined),
  });
}
