import React, { FC } from "react";
import { Button, Icon } from "ss-ui";
import { useTranslation } from "react-i18next";

import { IActiveSession } from "core";

import { Payload as payloadCloseSession } from "store/routines/user/closeSession";

interface Props {
  session: IActiveSession;
  closeSession?: (payload: payloadCloseSession) => void;
}

const Session: FC<Props> = ({ session, closeSession = () => ({}) }) => {
  const { t } = useTranslation();

  // <li className={`session-item ${session.current ? "current" : ""}`} key={session.id} id="session_item">
  return (
    <li className="session-item" key={session.id} id="session_item">
      <div className="left">
        <div className="name">
          {session.username}, {session.application}
          {session.version && <span className="version">{session.version}</span>}
        </div>
        <div className="info">
          <div className="device-icon">
            <Icon name="icon-screen-desktop" />
          </div>
          <div>
            <span className="device">
              {session.deviceName}, {session.deviceId}
            </span>
            <span className="location">
              {session.ip} {session.location.city && `${session.location.city}, `}
              {session.location.country}
            </span>
          </div>
        </div>
      </div>
      <div className="right">
        {/* {session.current ? (
        <div className="status-current">{t("settings_active_session_current_session")}</div>
        ) : (
        <div className="last-activity">{session.opened}</div>
        )} */}
        <div className="last-activity">{session.opened}</div>
        {session.current ? (
          <Button theme="success-link" size={30} disabled id="btn_active_session">
            {t("settings_active_session_current_session")}
          </Button>
        ) : (
          <Button theme="primary-link" size={30} onClick={() => closeSession({ id: session.id, current: session.current })} id="btn_close_session">
            {t("settings_active_session_btn_close_session")}
          </Button>
        )}
      </div>
    </li>
  );
};

export default Session;
