import { get } from "lodash";
import { Action } from "redux-actions";

import {
  getHash,
  IManageSessionsRequest,
  LOCAL_STORAGE,
  ROLE_TYPE,
  saveDataToLS,
  MainRoutes,
  typeAuthenticateOptions,
  typeYubikeyList,
} from "core";

import {
  confirmOTPActivation,
  closeSession,
  closeSessions,
  deleteYubikey,
  getAvatar,
  getProfile,
  getQRCode,
  getRegisterYubikey,
  getSessions,
  installPassword,
  login,
  loginAzure,
  loginYubikey,
  logout,
  OTPHashActivation,
  resetAdminPassword,
  resetPassword,
  setAvatar,
  setUnsubscribe,
  updateOTP,
  updateYubikeyEnable,
  userInit,
} from "store/actions";

import { GetProfileOTPResponse } from "store/routines/user/getProfile";

export interface State {
  QRCode?: string;
  activeSessions: IManageSessionsRequest;
  authenticateOptions?: typeAuthenticateOptions;
  avatar?: string;
  email?: string;
  error?: any;
  errorResetPassGlobal?: string;
  errorResetPassNew?: string;
  errorResetPassOld?: string;
  fullName?: string;
  initialization: boolean;
  isDone: boolean;
  isLoading: boolean;
  isMicrosoft: boolean;
  isUnsubscribe?: boolean;
  otp?: GetProfileOTPResponse;
  otpError?: string;
  refreshToken?: string;
  refreshToken_expires?: number;
  role: ROLE_TYPE;
  scope?: string;
  securityKey: string[];
  token?: string;
  token_expires?: number;
  tokenType?: string;
  unsubscribeToken?: string;
  userId?: string;
  username?: string;
  yubikeyEnable?: boolean;
  yubikeys: typeYubikeyList[];
}

const initialState: State = {
  token: undefined,
  token_expires: undefined,
  activeSessions: {
    count: 0,
    limit: 0,
    offset: 0,
    tokens: [],
    user: null,
  },
  authenticateOptions: undefined,
  avatar: undefined,
  email: undefined,
  error: undefined,
  errorResetPassGlobal: "",
  errorResetPassNew: "",
  errorResetPassOld: "",
  fullName: undefined,
  initialization: true,
  isDone: false,
  isLoading: false,
  isMicrosoft: false,
  isUnsubscribe: false,
  otp: undefined,
  otpError: undefined,
  refreshToken: undefined,
  refreshToken_expires: undefined,
  role: ROLE_TYPE.FREE_USER,
  scope: undefined,
  securityKey: [],
  tokenType: undefined,
  unsubscribeToken: undefined,
  userId: undefined,
  username: undefined,
  QRCode: undefined,
  yubikeyEnable: false,
  yubikeys: [],
};

export default function user(state: State = initialState, { payload, type }: Action<any>): State {
  switch (type) {
    case confirmOTPActivation.TRIGGER:
    case closeSession.TRIGGER:
    case closeSessions.TRIGGER:
    case deleteYubikey.TRIGGER:
    case getAvatar.TRIGGER:
    case getProfile.TRIGGER:
    case getQRCode.TRIGGER:
    case getRegisterYubikey.TRIGGER:
    case getSessions.TRIGGER:
    case installPassword.TRIGGER:
    case login.TRIGGER:
    case loginAzure.TRIGGER:
    case OTPHashActivation.TRIGGER:
    case resetAdminPassword.TRIGGER:
    case setAvatar.TRIGGER:
    case updateOTP.TRIGGER:
    case updateYubikeyEnable.TRIGGER:
    case userInit.TRIGGER:
      return {
        ...state,
        error: undefined,
        isLoading: true,
      };

    case setUnsubscribe.TRIGGER:
    case resetPassword.TRIGGER:
      return {
        ...state,
        isLoading: true,
      };

    case closeSession.SUCCESS:
    case closeSessions.SUCCESS:
    case installPassword.SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case confirmOTPActivation.SUCCESS:
      return {
        ...state,
        username: undefined,
        isLoading: false,
      };

    case deleteYubikey.SUCCESS:
      return {
        ...state,
        isLoading: false,
        yubikeys: state.yubikeys.filter(({ id }) => id !== payload.id),
      };

    case getQRCode.SUCCESS:
      return {
        ...state,
        QRCode: payload,
        isLoading: false,
      };

    case getRegisterYubikey.SUCCESS:
      return {
        ...state,
        yubikeys: payload,
        isLoading: false,
      };

    case getSessions.SUCCESS:
      return {
        ...state,
        activeSessions: payload,
        isLoading: false,
      };

    case resetAdminPassword.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDone: true,
      };

    case setAvatar.SUCCESS:
    case getAvatar.SUCCESS:
      return {
        ...state,
        avatar: payload,
        isLoading: false,
      };

    case getProfile.SUCCESS:
      return {
        ...state,
        fullName: payload.full_name,
        email: payload.email,
        role: payload.roles || payload.role || ROLE_TYPE.FREE_USER,
        otp: payload.otp || undefined,
        yubikeyEnable: payload.yubikey_enable,
        isLoading: false,
      };

    case userInit.SUCCESS:
      return {
        ...state,
        ...payload,
      };

    case updateOTP.SUCCESS:
      return {
        ...state,
        otp: { ...state.otp, ...payload.otp },
        yubikeyEnable: payload.yubikey_enable,
        isLoading: false,
      };

    case updateYubikeyEnable.SUCCESS:
      return {
        ...state,
        otp: { ...state.otp, ...payload.otp },
        yubikeyEnable: payload.yubikey_enable,
        isLoading: false,
      };

    case setUnsubscribe.SUCCESS:
      saveDataToLS(LOCAL_STORAGE.USER, {
        isUnsubscribe: undefined,
        unsubscribeToken: undefined,
      });
      return {
        ...state,
        isLoading: false,
        isUnsubscribe: false,
        unsubscribeToken: undefined,
      };

    case resetPassword.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDone: true,
      };

    case logout.SUCCESS:
      if (!Object.values(MainRoutes).includes(window.location.pathname)) {
        saveDataToLS(LOCAL_STORAGE.PREVIOUS_SESSION_URL, {
          username: getHash(state.username || "username"),
          url: window.location.pathname,
        });
      }

      return {
        ...state,
        token: undefined,
        avatar: undefined,
        email: undefined,
        isDone: false,
        isUnsubscribe: false,
        refreshToken: undefined,
        role: ROLE_TYPE.FREE_USER,
        scope: undefined,
        securityKey: [],
        tokenType: undefined,
        unsubscribeToken: undefined,
        username: undefined,
        userId: undefined,
      };

    case loginYubikey.SUCCESS:
    case loginAzure.SUCCESS:
    case login.SUCCESS:
      return {
        ...state,
        token: get(payload, "access_token", undefined),
        token_expires: get(payload, "access_token_expires_at", undefined),
        error: undefined,
        isLoading: false,
        isMicrosoft: get(payload, "isMicrosoft", undefined),
        isUnsubscribe: get(payload, "isUnsubscribe", undefined),
        refreshToken: get(payload, "refresh_token", undefined),
        refreshToken_expires: get(payload, "refresh_token_expires_at", undefined),
        role: get(payload, "roles", undefined),
        scope: get(payload, "scope", undefined),
        securityKey: get(payload, "securityKey", undefined),
        tokenType: get(payload, "token_type", undefined),
        unsubscribeToken: get(payload, "unsubscribeToken", undefined),
        username: get(payload, "username", undefined),
        userId: get(payload, "userId", undefined),
      };

    case OTPHashActivation.SUCCESS:
      return {
        ...state,
        username: get(payload, "userName", null),
        isLoading: false,
      };

    case confirmOTPActivation.FAILURE:
    case closeSession.FAILURE:
    case closeSessions.FAILURE:
    case deleteYubikey.FAILURE:
    case getAvatar.FAILURE:
    case getProfile.FAILURE:
    case getQRCode.FAILURE:
    case getRegisterYubikey.FAILURE:
    case getSessions.FAILURE:
    case installPassword.FAILURE:
    case loginAzure.FAILURE:
    case resetPassword.FAILURE:
    case setAvatar.FAILURE:
    case updateOTP.FAILURE:
    case updateYubikeyEnable.FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };

    case OTPHashActivation.FAILURE:
      return {
        ...state,
        username: undefined,
        error: payload,
        isLoading: false,
      };

    case login.FAILURE:
      return {
        ...state,
        error: typeof payload === "string" ? payload : undefined,
        authenticateOptions:
          typeof payload === "string"
            ? undefined
            : {
                authenticateOptions: payload.authenticate_options,
                user: payload.user,
              },
        isLoading: false,
      };

    case setUnsubscribe.FAILURE: {
      saveDataToLS(LOCAL_STORAGE.USER, {
        isUnsubscribe: undefined,
        unsubscribeToken: undefined,
      });
      return {
        ...state,
        isLoading: false,
        isUnsubscribe: false,
        unsubscribeToken: undefined,
      };
    }

    case resetAdminPassword.FAILURE:
      return {
        ...state,
        errorResetPassOld: payload.oldPassword ? payload.oldPassword[0] : "",
        errorResetPassNew: payload.password ? payload.password.first[0] : "",
        errorResetPassGlobal: payload === "error_connection" ? "error_connection" : "",
        isDone: false,
        isLoading: false,
      };

    case confirmOTPActivation.FULFILL:
    case deleteYubikey.FULFILL:
    case getQRCode.FULFILL:
    case getRegisterYubikey.FULFILL:
    case installPassword.FULFILL:
    case login.FULFILL:
    case loginAzure.FULFILL:
    case OTPHashActivation.FULFILL:
    case updateYubikeyEnable.FULFILL:
      return {
        ...state,
        isLoading: false,
      };

    case userInit.FULFILL:
      return {
        ...state,
        initialization: false,
        isLoading: false,
      };

    default:
      return state;
  }
}
